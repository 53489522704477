<template>
  <swiper class="swiper-navigations" :options="swiperOptions">
    <swiper-slide v-for="(data, index) in images" :key="index">
      <b-img :src="data.url" fluid />
    </swiper-slide>

    <!-- Add Arrows -->
    <div slot="button-next" class="swiper-button-next" />
    <div slot="button-prev" class="swiper-button-prev" />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    images: Array,
  },
  data() {
    return {
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
