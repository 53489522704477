<template>
	<b-modal
		id="product-details-modal"
		ref="product-details-modal"
		scrollable
		:title="data ? `${data.nameEng} / ${data.nameGeo}` : ''"
		hide-footer
		size="xl"
		@hide="hideModal"
	>
		<div v-if="data">
			<b-col>
				<div v-if="data.mainImage">
					<label for="">Main image</label>
					<div>
						<a :href="data.mainImage.url" target="_blank">
							<img :src="data.mainImage.url" class="popup-gallery-img" />
						</a>
					</div>
				</div>
				<div v-if="data.gallery.length">
					<label>Gallery images</label>
					<div class="d-flex flex-wrap">
						<img
							v-for="img in data.gallery"
							:key="img.id"
							:src="img.url"
							class="popup-gallery-img"
						/>
					</div>
				</div>

				<h2 class="d-flex justify-content-center mt-1">About Product:</h2>
			</b-col>
			<app-collapse>
				<app-collapse-item title="Basic Information" class="mb-2">
					<b-row>
						<b-col md="3">
							<b-form-group label="Name GEO" label-for="i-name-geo">
								<b-form-input
									id="i-geo"
									v-model="data.nameGeo"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col md="3">
							<b-form-group label="Name ENG" label-for="i-name-eng">
								<b-form-input
									v-model="data.nameEng"
									disabled
									id="i-eng"
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col md="3">
							<b-form-group label="UPC/SKU" label-for="i-name-eng">
								<b-form-input
									v-model="data.upcOrSku"
									disabled
									id="i-eng"
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="Service type" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									disabled
									v-model="serviceType"
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="Gift type" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="giftType"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="Commission Fee" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="data.commissionFeePercent"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group
								label="Product Category (ENG)"
								label-for="i-name-eng"
							>
								<b-form-input
									id="i-eng"
									v-model="data.productCategory.productCategoryNameEng"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group
								label="Product Category (GEO)"
								label-for="i-name-eng"
							>
								<b-form-input
									id="i-eng"
									v-model="data.productCategory.productCategoryNameGeo"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group label="Branches" label-for="branches">
								<b-list-group v-for="branch in data.branches" :key="branch.id">
									<b-list-group-item class="mb-1">{{
										branch.nameEng
									}}</b-list-group-item>
								</b-list-group>
							</b-form-group>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item
					v-if="data.attributes"
					title="Attributes"
					class="mb-2"
				>
					<div
						v-for="attribute in data.attributes"
						:key="attribute.id"
						class="p-1 border my-1"
					>
						<p class="mb-0">
							{{ attribute.nameGeo }} / {{ attribute.nameEng }}
						</p>
						<small class="mb-0">
							{{ attribute.value.valueEng }} /
							{{ attribute.value.valueGeo }}</small
						>
					</div>
				</app-collapse-item>
				<app-collapse-item title="Location" class="mb-2">
					<b-row>
						<b-col md="6">
							<b-form-group label="Location" label-for="i-name-geo">
								<b-form-input
									v-model="data.location"
									id="i-geo"
									disabled
									placeholder="longitude and latitude"
								/>
								<b-form-group
									label="Location Free"
									label-for="i-name-geo"
									class="mt-1"
								>
									<b-form-checkbox
										v-model="data.isLocationFree"
										plain
										disabled
										class="mb-2"
									>
										Location Free
									</b-form-checkbox>
								</b-form-group>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="City" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="data.city.cityNameEng"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="District" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="data.district.districtNameEng"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item title="Characteristics" class="mb-2">
					<b-row>
						<b-col sm="2">
							<b-form-group label="Minimum Age" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="data.minAge"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="2">
							<b-form-group label="Maximum Age" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="data.maxAge"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group
								label="Product Target Gender"
								label-for="i-name-eng"
							>
								<b-form-input
									id="i-eng"
									v-model="productTargetGender"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item
					v-if="data.configurations"
					title="Product Configuration"
					class="mb-2"
				>
					<app-collapse
						v-for="item in data.configurations"
						:key="item.productSubCategoryId"
						type="margin"
						accordion
					>
						<app-collapse-item :title="item.colorCode">
							<div
								v-if="item.mainImage"
								class="image-wrapper"
								style="width: 18rem"
							>
								<b-form-group label="Product Main Image" label-for="i-name-eng">
									<img :src="item.mainImage.url" alt="main image" />
								</b-form-group>
							</div>
							<b-form-group label="Product Gallery" label-for="i-name-eng">
								<Swiper :images="item.gallery" />
							</b-form-group>
							<b-form-group label="Product Options" label-for="i-name-eng">
								<b-list-group v-for="option in item.options" :key="option.id">
									<b-list-group-item>{{
										`Size: ${option.sizeOption}, UPC/SKU: ${option.upcOrSku}, Quantity: ${option.count}`
									}}</b-list-group-item>
								</b-list-group>
							</b-form-group>
						</app-collapse-item>
					</app-collapse>
				</app-collapse-item>
				<app-collapse-item title="Product Description" class="mb-2">
					<b-row>
						<b-col md="6">
							<b-form-group label="Product Description (ENG)">
								<b-form-textarea
									id="textarea-state"
									v-model="data.descriptionEng"
									disabled
									placeholder="Enter only 200 characters"
									rows="2"
								/>
							</b-form-group>
							<b-form-group label="Product Description (GEO)">
								<b-form-textarea
									id="textarea-state"
									disabled
									v-model="data.descriptionGeo"
									placeholder="Enter only 200 characters"
									rows="2"
								/>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group label="Terms Of Use (ENG)">
								<b-form-textarea
									id="textarea-state"
									disabled
									v-model="data.useTermsEng"
									placeholder="Enter only 200 characters"
									rows="2"
								/>
							</b-form-group>
							<b-form-group label="Terms Of Use (GEO)">
								<b-form-textarea
									id="textarea-state"
									v-model="data.useTermsGeo"
									disabled
									placeholder="Enter only 200 characters"
									rows="2"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="Active From" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="activeFrom"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label="Active To" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="activeTo"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
						<b-col v-if="data.sale" sm="3">
							<b-form-group label="Sale" label-for="i-name-eng">
								<div v-if="data.sale.type === 1">
									<b-input-group append="#" class="">
										<b-form-input
											placeholder="0"
											disabled
											type="number"
											v-model="data.sale.fixed"
										/>
									</b-input-group>
								</div>
								<div v-else>
									<b-input-group append="%" class="">
										<b-form-input
											placeholder="0"
											disabled
											type="number"
											v-model="data.sale.percent"
										/>
									</b-input-group>
								</div>
							</b-form-group>
						</b-col>
						<b-col v-if="data.sale" sm="3">
							<b-form-group label="Sale Until At" label-for="i-name-eng">
								<b-form-input
									id="i-eng"
									v-model="saleUntilAt"
									disabled
									placeholder="Name here..."
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</app-collapse-item>
			</app-collapse>
		</div>
	</b-modal>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
// eslint-disable-next-line object-curly-newline
import {
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BListGroup,
	BFormCheckbox,
	BInputGroup,
	BListGroupItem,
	BFormTextarea,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Swiper from './Swiper.vue';

export default {
	components: {
		Swiper,
		BInputGroup,
		BCol,
		BFormCheckbox,
		BRow,
		BListGroup,
		BListGroupItem,
		AppCollapse,
		BFormInput,
		BFormGroup,
		AppCollapseItem,
		BFormTextarea,
	},
	props: {
		data: Object,
	},
	data() {
		return {};
	},
	computed: {
		serviceType: {
			get() {
				return this.serviceTypeLabel(this.data.serviceType);
			},
		},
		giftType: {
			get() {
				return this.giftTypeLabel(this.data.giftType);
			},
		},
		activeFrom: {
			get() {
				return this.data.activeFrom ? this.data.activeFrom.slice(0, 16) : '';
			},
		},
		activeTo: {
			get() {
				return this.data.activeTo ? this.data.activeTo.slice(0, 16) : '';
			},
		},
		saleUntilAt: {
			get() {
				return this.data.saleUntilAt ? this.data.saleUntilAt.slice(0, 16) : '';
			},
		},
		productTargetGender: {
			get() {
				return this.genderLabel(this.data.productTargetGender);
			},
		},
	},
	methods: {
		hidePopup() {
			this.$emit('onHidePopup', false);
		},
		genderLabel(id) {
			switch (id) {
				case 1:
					return 'Male';
				case 2:
					return 'Female';
				case 3:
					return 'Both';
				default:
					return 'Gender Type Not Found';
				// code block
			}
		},
		giftTypeLabel(id) {
			switch (id) {
				case 1:
					return 'Both';
				case 2:
					return 'Daily';
				case 3:
					return 'Holiday';
				default:
					return 'Service Type Not Found';
				// code block
			}
		},
		serviceTypeLabel(id) {
			switch (id) {
				case 1:
					return 'Experiential';
				case 2:
					return 'Material';
				case 3:
					return 'Digital';
				case 4:
					// code block
					break;
				default:
					return 'Service Type Not Found';
				// code block
			}
			return 'Service Type';
		},
		hideModal() {
			// this.$refs["my-modal"].hide();
			this.hidePopup();
		},
	},
};
</script>

<style lang="scss">
.buttons-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;

	button {
		width: 47%;
	}
}
.feather-24 {
	width: 16px;
	height: 16px;
}
.image-wrapper {
	margin: 0 auto;
	width: auto !important;
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	> img {
		width: 100%;
	}
}

.popup-gallery-img {
	max-height: 125px;
	margin: 0.5rem;
	border-radius: 15px;
}
</style>
