<template>
	<b-card
		:header="
			this.$i18n.t('products.review-products') +
			' ' +
			this.$i18n.t('products.products')
		"
		header-bg-variant="primary"
		header-text-variant="white"
	>
		<!-- <b-button :click="($i18n.locale = 'en')">asd</b-button> -->
		<ProductPopup :data="selectedProduct" @onHidePopup="onClickChild" />

		<div>
			<ProductTypeSwitch :product-type-switch.sync="productTypeSwitch" />
		</div>
		<b-table
			:fields="fields"
			:items="computedProductsList"
			bordered
			responsive
			hover
			class="products-table shadow-sm rounded"
			show-empty
			empty-text="No matching records found"
			small
			@row-clicked="onRowClick"
		>
			<template #cell(actions)="data">
				<b-button
					variant="flat-primary"
					class="btn-icon"
					@click="editProduct(data.item.id)"
				>
					<feather-icon size="16" icon="EditIcon" />
				</b-button>
				<b-button
					variant="flat-primary"
					class="btn-icon"
					@click="dublicateProduct(data.item.id)"
				>
					<feather-icon size="16" icon="CopyIcon" />
				</b-button>
				<b-button
					class="btn-icon"
					variant="flat-danger"
					@click="removeProduct(data.item.id)"
				>
					<feather-icon size="16" icon="TrashIcon" />
				</b-button>
			</template>
		</b-table>
		<Pagination
			:fetch-data="getProducts"
			fetch-type="review-products"
			:total-rows="totalRows"
			:take.sync="take"
		/>
	</b-card>
</template>

<script>
// /* eslint-disable no-console */
import { BCard, BButton, BTable } from 'bootstrap-vue';
import AddUserForm from '@/views/components/AddUserForm/AddUserForm.vue';
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import ProductPopup from './ProductPopup.vue';
import 'vue-good-table/dist/vue-good-table.css';
import Pagination from '../components/Pagination/Pagination.vue';
import ProductTypeSwitch from './components/ProductTypeSwitch.vue';

export default {
	components: {
		BCard,
		BTable,
		BButton,
		// eslint-disable-next-line vue/no-unused-components
		AddUserForm,
		ProductPopup,
		Pagination,
		ProductTypeSwitch,
	},
	data() {
		return {
			pageLength: 10,
			dir: false,
			totalRows: 0,
			action: null,
			take: 20,
			skip: 0,
			selectedProduct: null,
			productTypeSwitch: 'pending',
			productsList: [],
			productStatusList: {
				1: 'Pending',
				2: 'Rejected',
				3: 'Approved',
				4: 'Edited',
			},
			fields: [
				{
					label: this.$i18n.t('products.actions'),
					key: 'actions',
				},
				{
					label: this.$i18n.t('products.name'),
					key: 'nameGeo',
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
					tdClass: 'nameColumn',
				},
				{
					key: 'price',
					label: this.$i18n.t('products.price'),
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'count',
					label: this.$i18n.t('products.count'),
				},
				{
					key: 'commissionFeePercent',
					label: this.$i18n.t('products.comission'),
				},
				{
					label: this.$i18n.t('products.created_at'),
					key: 'createdAt',
					sortable: false,
				},
				{
					label: this.$i18n.t('products.category'),
					key: 'productCategory',
					formatter: (value) =>
						`${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.sub-category'),
					key: 'productSubCategory',
					formatter: (value) =>
						`${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.generic-category'),
					key: 'productGenericCategory',
					formatter: (value) =>
						`${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
					sortable: false,
				},
				{
					label: this.$i18n.t('products.status'),
					key: 'status',
					formatter: (value) => this.productStatusList[value],
				},
				{
					label: this.$i18n.t('products.sku'),
					key: 'upcOrSku',
				},
			],
			searchTerm: '',
		};
	},
	computed: {
		...mapState('products', ['products']),
		computedProductsList() {
			if (this.productTypeSwitch === 'pending') {
				return this.productsList.filter(
					(e) => e.status === 1 || e.status === 4
				);
			}
			return this.productsList.filter((e) => e.status === 2);
		},
	},
	watch: {
		products(newVal) {
			console.log(newVal);
			this.productsList = newVal.products;
		},
	},
	created() {
		this.getProducts({
			skip: 0,
			take: 10,
			searchKey: '',
			productType: 'review-products',
		});
	},
	methods: {
		...mapActions('products', ['getProducts', 'deleteProductById']),
		dublicateProduct(id) {
			this.$router.push(`/copy-product/${id}`);
		},
		onClickChild() {
			this.selectedProduct = null;
		},
		editProduct(id) {
			this.$router.push(`/edit-product/${id}`);
		},
		removeProduct(id) {
			console.log('removing ', id);
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteProductById(id).then(() => {
						this.getProducts({
							skip: 0,
							take: 10,
							searchKey: '',
							productType: 'review-products',
						});
					});
				}
			});
		},
		onRowClick(item) {
			// this.selectedProduct = item;
			// console.log(item);
			// this.$bvModal.show('product-details-modal');
			this.$router.push(`/edit-product/${item.id}`);
			// this.$refs["my-modal"].show();
		},
	},
};
</script>
<style lang="scss">
.buttonsWrapper {
	display: flex;
	justify-content: space-between;
	@media (max-width: 600px) {
		flex-direction: column;
	}
}
.custom-search {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
}
.form-group {
	@media (max-width: 600px) {
		width: 100%;
	}
}
.products-table th,
.products-table td {
	// min-width: 200px ;
	white-space: nowrap;
}
.nameColumn {
	width: 400px;
}
</style>
